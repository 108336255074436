import React from "react"
import { Layout } from "@components/layout/layout"

// core components
import GridContainer from "@components/Grid/GridContainer.js";
import GridItem from "@components/Grid/GridItem.js";
import { makeStyles } from "@material-ui/core/styles";

import Button from "@components/customButtons/button.js"

import notFoundPageStyle from "assets/jss/page/404/style.js";

import shizaru from "assets/image/page/404/shizaru.png";

const useStylesNotFound = makeStyles(notFoundPageStyle);

export default function NotFound({ data, pageContext, location, ...props }) {
  const classesNotFound = useStylesNotFound()
  const node = {
    context: {
      title: "Not Found",
      description: "",
      slug: "/404",
      keywords: [""]
    }
  }

  return (
    <Layout
      pageNode={node}
      location={location}
      pageContext={pageContext}
      {...props}
    >
      <div className={classesNotFound.section}>
        <GridContainer justify="center">
          <GridItem cs={12} sm={12} md={8}>
            <div>
              <h1>Not Found</h1>
            </div>
            <img
              src={shizaru}
              alt="Page Not Found"
              title="Shizaru - Page Not Found"
            />
          </GridItem>
          <GridItem cs={12} sm={12} md={8}>
            <div>
              <div>
                Shizaru, the do no evil mystic apes says his brothers cannot
                find the page you are looking for.
              </div>
              <Button
                href="/"
                color="transparent"
                // className={ data.sitePage.context.slug == node.context.slug ?classNames(classes.navLink):classes.navLink }
                title="Back to home"
                aria-label="Back to home"
              ></Button>
            </div>
          </GridItem>
        </GridContainer>
      </div>
    </Layout>
  )
}