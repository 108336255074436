// import {
//     backgroundColor,
//     container,
//     defaultFont,
//     primaryColor,
//     notificationColor,
//     otherColor,
//     transition,
//     boxShadow,
//     drawerWidth
//   } from "assets/jss/common/threemysticapes.js"
  
  
  const errorPage404Style = {
    section: {
        padding: "70px 0",
        textAlign: "center"
      },
      title: {
        marginBottom: "1rem",
        marginTop: "30px",
        minHeight: "32px",
        textDecoration: "none"
      },
      description: {
        color: "#999"
      }
  };  
  export default errorPage404Style;
  